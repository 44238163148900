<template>
  <div class="py-[0.78rem]">
    <template>
      <div class="flex px-6 w-full justify-between items-center">
        <div class="w-full flex justify-center items-center">
          <div v-if="pagination.totalItems > pagination.limit">
            <TablePagination
              :clickedMethod="paginateBioLinks"
              :resultCount="pagination.totalItems"
              :itemsPerPage="pagination.limit"
              :currentPage="pagination.page"
              :totalPagesNumber="pagination.totalPages"
            ></TablePagination>
          </div>
        </div>
        <PerPageFilter
          :limit="pagination.limit"
          @click="pagination.limit = $event; paginateBioLinks"
        ></PerPageFilter>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      pagination: {},
      payload: {
        archive: false,
        page: 1,
        limit: 10,
        sort: 'created_desc'
      },
    }
  },
  components: {
    TablePagination: () => import('@/ui/ui-kit/v2/TablePagination.vue'),
    PerPageFilter: () => import('@/ui/ui-kit/v2/PerPageFilter.vue')
  },
  computed: {
    ...mapGetters([
      'getBioLinkPagination'
    ])
  },
  async created () {
    const params = this.getParamsFromURL()
    this.payload.page = params.page
    this.payload.limit = params.limit
    if (this.payload.search && this.payload.search.length > 0) this.payload.page = 1
    if (this.payload.limit && this.payload.limit > 10) {
      this.resultsText = this.payload.limit + ' Results'
      this.payload.page = 1
    }
  },
  methods: {
    ...mapActions([
      'showBio',
      'fetchBioLinkAnalytics'
    ]),
    async fetchLinks () {
      const pagination = await this.showBio(this.payload)
      this.setBioLinksPagination(pagination)
      await this.fetchBioLinkAnalytics()
    },
    async paginateBioLinks (page) {
      await this.$router.replace({ query: { ...this.$route.query, page: page } })
      this.payload.page = page
      this.pagination.page = page
      await this.fetchLinks()
    },
    setBioLinksPagination (pagination) {
      this.pagination = {
        page: pagination.current_page,
        totalItems: pagination.total,
        limit: pagination.per_page,
        totalPages: pagination.last_page
      }
    }
  }
}
</script>
